<template>
    <div>
        <head-layout :head-btn-options="headBtnOptions" :head-title="`[${dictValue}]` + $t('cip.plat.sys.dict.title.indexConfigHeadTitle')"
            @head-add="getNew" @head-romve="handleDelete" @head-cancel="headCancel">
        </head-layout>
        <grid-head-layout ref="searchFrom" :search-columns="searchColumns" v-model="searchForm"
            @grid-head-search="searchChange" @grid-head-empty="searchReset">
        </grid-head-layout>
        <grid-layout ref="gridLayOut" :tableOptions="optionChild" :tableData="dataChild" :table-loading="loadingChild"
            :data-total="dataTotal" :page="pageChild" @gird-handle-select-click="selectionChange"
            :gridRowBtn="gridRowBtn" @grid-edit="rowUpdate" @grid-romve="rowDel" @row-update="addUpdate"
            @tree-load='treeLoad' @grid-addChild="handleAdd"></grid-layout>
        <el-drawer class="el-drawer__wrapper avue-dialog avue-crud__dialog" :visible.sync="isShow" size="70%"
            style="margin-top: 50px;" :show-close="false">
            <template slot="title">
                <dialog-head-btn @dialog-head-save-click="save" @dialog-head-cancel-click="cancel" head-title=""
                    icon="">
                </dialog-head-btn>
            </template>
            <form-layout v-if="isShow" :column="optionChild.column" :dataForm="dataObj" ref="formLayout"></form-layout>
        </el-drawer>
        <!-- <avue-crud :option="optionChild" :table-loading="loadingChild" :data="dataChild" ref="crudChild" v-model="formChild"
      :permission="permissionList" :before-open="beforeOpenChild" :before-close="beforeCloseChild"
      @row-del="rowDelChild" @row-update="rowUpdateChild" @row-save="rowSaveChild" @search-change="searchChangeChild"
      @search-reset="searchResetChild" @selection-change="selectionChangeChild" @current-change="currentChangeChild"
      @size-change="sizeChangeChild" @refresh-change="refreshChangeChild" @on-load="onLoadChild">
      <template slot="menuLeft">
        <el-button type="danger" size="small" icon="el-icon-delete" v-if="permission.dict_delete" plain
          @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button type="text" icon="el-icon-circle-plus-outline" size="small"
          @click.stop="handleAdd(scope.row, scope.index)" v-if="userInfo.role_name.includes('administrator')">新增子项
        </el-button>
      </template>
      <template slot-scope="{row}" slot="isSealed">
        <el-tag>{{ row.isSealed === 0 ? '否' : '是' }}</el-tag>
      </template>
    </avue-crud> -->
    </div>
</template>

<script>
import {
    getParentList,
    getChildList,
    remove,
    update,
    add,
    getDict,
    getDictTree
} from "@/api/system/dictbiz";
import { mapGetters } from "vuex";
import { optionParent, optionChild } from "@/option/system/dict";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
export default {
    components: {
        HeadLayout,
        GridLayout,
        dialogHeadBtn,
        formLayout
    },
    data() {
        return {
            isShow: false,
            dataObj: '',
            searchForm: {},
            gridRowBtn: [
                {
                    label: this.$t('cip.cmn.btn.editBtn'),
                    emit: "grid-edit",
                    type: "text",
                    icon: ""
                }, {
                    label: this.$t('cip.cmn.btn.delBtn'),
                    emit: "grid-romve",
                    type: "text",
                    icon: ""
                },
                {
                    label: this.$t('cip.cmn.btn.addChildBtn'),
                    emit: "grid-addChild",
                    type: "text",
                    icon: ""
                }
            ],
            searchColumns: [
                {
                    label: "",
                    prop: "code",
                    search: true,
                    placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t('cip.plat.sys.dict.field.code'),

                },

                {
                    label: "",
                    placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t('cip.plat.sys.dict.field.dictValue'),
                    prop: "dictValue",
                    search: true,
                },
            ],
            dictValue: '',
            headBtnOptions: [{
                label: this.$t('cip.cmn.btn.addBtn'),
                emit: "head-add",
                type: "button",
                icon: ""
            },
            {
                label: this.$t('cip.cmn.btn.delBtn'),
                emit: "head-romve",
                type: "button",
                icon: ""
            }, {
                label: this.$t('cip.cmn.btn.celBtn'),
                emit: "head-cancel",
                type: "button",
                icon: ""
            }],
            loadingChild: true,
            pageChild: {
                pageSize: 10,
                pageSizes: [10, 20,30, 40, 50, 100],
                currentPage: 1,
                total: 0
            },
            parentId: '-1',
            dataChild: [],
            data: {},
            formChild: {},
            selectionList: [],
            optionChild: optionChild(this),
            addChildParentId: '',
        }
    },
    computed: {
        ...mapGetters(["userInfo", "permission"]),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.dict_add, false),
                delBtn: this.vaildData(this.permission.dict_delete, false),
                editBtn: this.vaildData(this.permission.dict_edit, false),
                viewBtn: false,
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(",");
        }
    },
    created() {
        // this.findObject(this.optionChild.column, "code").disabled = true;
        // this.findObject(this.optionChild.column, "parentId").disabled = true;
        // this.optionChild.viewBtn = false
        // this.optionChild.searchShow = false
        // let data = JSON.parse(decodeURIComponent(this.$route.query.formData))
        // this.parentId = data.id
        // console.log(this.parentId)
        // this.dictValue = data.dictValue;

        // const code = this.findObject(this.optionChild.column, "code");
        // code.value = data.code;
        // const parentId = this.findObject(this.optionChild.column, "parentId");
        // parentId.value = data.id;
        // this.onLoadChild(this.pageChild)

        this.findObject(this.optionChild.column, "code").disabled = true;
        this.findObject(this.optionChild.column, "parentId").disabled = true;
        this.optionChild.viewBtn = false
        this.optionChild.searchShow = false
        this.data = JSON.parse(decodeURIComponent(this.$route.query.formData))

        this.parentId = this.data.id
        console.log(this.parentId)
        this.dictValue = this.data.dictValue;

        const code = this.findObject(this.optionChild.column, "code");
        code.value = this.data.code;
        const parentId = this.findObject(this.optionChild.column, "parentId");
        parentId.value = this.data.id;
        this.onLoadChild(this.pageChild)
    },
    mounted() {
        this.initData();
    },
    methods: {
        getNew() {
            console.log(this.dictValue)
            this.$router.push({
                path: '/dictbiz/configEdit',
                query: {
                    pid: this.parentId,
                    type: 'add',
                    title: this.dictValue,
                    code: this.data.code,
                }
            })
        },
        save() {

            this.$refs.formLayout.$refs.form.validate(valid => {
                if (valid) {
                    let obj = this.$refs.formLayout.dataForm
                    obj.parentId = this.addChildParentId
                    let requestType = obj.hasOwnProperty('id') ? update : add
                    requestType(obj).then(() => {
                        this.onLoadChild(this.pageChild);
                        this.dataObj = ''
                        this.$message({
                            type: "success",
                            message: this.$t("cip.cmn.msg.success.operateSuccess")
                        });
                        this.isShow = false
                        // done();
                    }, error => {
                        window.console.log(error);
                        this.$refs.formLayout.$refs.form.allDisabled = false
                    });
                } else {
                    this.$refs.formLayout.$refs.form.allDisabled = false
                }
            })

        },
        cancel() {
            this.isShow = false;
            this.dataObj = ''
        },
        initData() {
            getDictTree().then(res => {
                const column = this.findObject(this.optionChild.column, "parentId");
                column.dicData = res.data.data;
            });
        },
        handleAdd(row) {
            this.formChild.parentId = row.id;

            this.$router.push({
                path: '/dictbiz/configEdit',
                query: {
                    pid: row.id,
                    type: 'addChild',
                    title: this.dictValue,
                    code: row.code
                }
            })

        },
        rowSave(row, done, loading) {
            const form = {
                ...row,
                dictKey: -1,
            };
            add(form).then(() => {
                this.onLoadChild(this.pageChild);
                this.$message({
                    type: "success",
                    message: this.$t("cip.cmn.msg.success.operateSuccess")
                });
                // done();
            }, error => {
                window.console.log(error);
                loading();
            });
        },
        rowUpdate(row, index, done, loading) {
            let data = encodeURIComponent(JSON.stringify(row))
            this.dataObj = row
            // this.isShow = true;
            this.$router.push({
                path: '/dictbiz/configEdit',
                query: {
                    pid: row.parentId,
                    id: row.id,
                    type: 'edit',
                    title: this.dictValue,
                }
            })
        },
        rowDel(row) {
            this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
                confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
                cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
                type: "warning"
            })
                .then(() => {
                    return remove(row.id);
                })
                .then(() => {
                    this.onLoadChild(this.pageChild);
                    this.$message({
                        type: "success",
                        message: this.$t("cip.cmn.msg.success.operateSuccess")
                    });
                });
        },
        handleRowClick(row) {
            this.$router.push({
                path: '/dict/config',
                query: {
                    formData: encodeURIComponent(JSON.stringify(row))
                }
            })
            // this.query = {};
            // this.parentId = row.id;
            // this.dictValue = row.dictValue;

            // const code = this.findObject(this.optionChild.column, "code");
            // code.value = row.code;
            // const parentId = this.findObject(this.optionChild.column, "parentId");
            // parentId.value = row.id;

            // this.box = true;
            // this.onLoadChild(this.pageChild);
        },
        searchReset() {
            this.query = {};
            this.onLoadChild(this.pageChild);
        },
        searchChange(params, done) {
            this.query = params;
            this.pageChild.currentPage = 1;
            this.onLoadChild(this.pageChild, params);
            // done();
        },
        selectionChange(list) {
            this.selectionList = list;
        },
        selectionClear() {
            this.selectionList = [];
            //  this.$refs.gridLayOut.selectionClear();
        },
        headCancel() {
            this.$router.$avueRouter.closeTag();
            this.$router.back();
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
                return;
            }
            this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
                confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
                cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
                type: "warning"
            })
                .then(() => {
                    return remove(this.ids);
                })
                .then(() => {
                    this.onLoadChild(this.pageChild);
                    this.$message({
                        type: "success",
                        message: this.$t("cip.cmn.msg.success.operateSuccess")
                    });
                    this.$refs.gridLayOut.selectionClear();
                });
        },
        beforeOpen(done, type) {
            if (["edit", "view"].includes(type)) {
                getDict(this.formParent.id).then(res => {
                    this.formParent = res.data.data;
                });
            }
            // done();
        },
        currentChange(currentPage) {
            this.pageChild.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.pageChild.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoadChild(this.pageChild, this.query);
        },
        rowSaveChild(row, done, loading) {
            add(row).then(() => {
                this.onLoadChild(this.pageChild);
                this.$message({
                    type: "success",
                    message: this.$t("cip.cmn.msg.success.operateSuccess")
                });
                // done();
            }, error => {
                window.console.log(error);
                loading();
            });
        },
        rowUpdateChild(row, index, done, loading) {
            update(row).then(() => {
                this.onLoadChild(this.pageChild);
                this.$message({
                    type: "success",
                    message: this.$t("cip.cmn.msg.success.operateSuccess")
                });
                // done();
            }, error => {
                window.console.log(error);
                loading();
            });
        },
        rowDelChild(row) {
            this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
                confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
                cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
                type: "warning"
            })
                .then(() => {
                    return remove(row.id);
                })
                .then(() => {
                    this.onLoadChild(this.pageChild);
                    this.$message({
                        type: "success",
                        message: this.$t("cip.cmn.msg.success.operateSuccess")
                    });
                });
        },
        searchResetChild() {
            this.query = {};
            this.onLoadChild(this.pageChild);
        },
        searchChangeChild(params, done) {
            this.query = params;
            this.pageChild.currentPage = 1;
            this.onLoadChild(this.pageChild, params);
            // done();
        },
        selectionChangeChild(list) {
            this.selectionList = list;
        },
        selectionClearChild() {
            this.selectionList = [];
            this.$refs.crudChild.toggleSelection();
        },
        handleDeleteChild() {
            if (this.selectionList.length === 0) {
                this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
                return;
            }
            this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
                confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
                cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
                type: "warning"
            })
                .then(() => {
                    return remove(this.ids);
                })
                .then(() => {
                    this.onLoadChild(this.pageChild);
                    this.$message({
                        type: "success",
                        message: this.$t("cip.cmn.msg.success.operateSuccess")
                    });
                    this.$refs.crudChild.toggleSelection();
                });
        },
        beforeOpenChild(done, type) {
            if (["add", "edit"].includes(type)) {
                this.initData();
            }
            if (["edit", "view"].includes(type)) {
                getDict(this.formChild.id).then(res => {
                    this.formChild = res.data.data;
                });
            }
            // done();
        },
        beforeCloseChild(done) {
            this.$refs.crudChild.value.parentId = this.parentId;
            this.$refs.crudChild.option.column.filter(item => {
                if (item.prop === "parentId") {
                    item.value = this.parentId;
                }
            });
            // done();
        },
        currentChangeChild(currentPage) {
            this.pageChild.currentPage = currentPage;
        },
        sizeChangeChild(pageSize) {
            this.pageChild.pageSize = pageSize;
        },
        refreshChangeChild() {
            this.onLoadChild(this.pageChild, this.query);
        },

        onLoadChild(page, params = {}) {
            this.loadingChild = true;
            getChildList(
                page.currentPage,
                page.pageSize,
                this.parentId,
                Object.assign(params, this.query)
            ).then(res => {
                this.dataChild = res.data.data;
                //树形表不用分页
                // this.$refs.gridLayOut.page.total = this.dataChild.length
                this.loadingChild = false;
                this.selectionClear();
            });
        }
    }
}
</script>

<style>
</style>
